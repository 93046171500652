<template>
  <div class="soloevent-wrapper">
    <div class="nest-loader" v-if="isDataLoading == true">
      <CircularLoader />
    </div>
    <div v-else>
      <div class="container-fluid soloevent-banner">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7">
              <div class="_content-box">
                <img class="nest-logo" src="../assets/nest-logo.png" alt="" />
                <h1 v-if="nestEvent != null" v-html="nestEvent.title"></h1>
                <h1 v-else>
                  {{ banners.title }}
                </h1>
                <p class="date" v-if="nestEvent != null">
                  {{ nestEvent.start_time }}
                </p>
                <p v-if="nestEvent != null">
                  {{ nestEvent.short_description }}
                </p>
                <div class="cta-row">
                  <router-link
                    class="btn cta-primary"
                    :to="{ name: 'login', query: { name: 'Nest' } }"
                    >Enroll now</router-link
                  >
                  <!-- <button class="btn cta-primary">Enroll now</button> -->
                  <a
                    v-if="nestEvent != null"
                    href="#soon-section"
                    class="btn cta-icon"
                  >
                    <a
                      class="headers"
                      href="javascript:document.getElementById('soon-section').scrollIntoView(true);"
                      ><i class="fas fa-play" style="margin-top : 5px"></i
                      ><span>View Details</span>
                    </a>
                  </a>
                  <a
                    v-else
                    :href="about.youtube_link"
                    target="_blank"
                    class="btn cta-icon"
                  >
                    <i class="fas fa-play"></i>
                    <span>View Details</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="_img-wrap">
                <img src="../assets/banner-hero-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-section">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-6">
              <div class="_content">
                <h5 v-html="about.title"></h5>
                <h3>
                  We make it the way your <br />
                  <span>child</span> loves it
                </h3>
                <p v-html="about.contents"></p>
                <a
                  href="#"
                  target="_blank"
                  class="btn cta-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                >
                  <i class="fas fa-play"></i><span>Watch now</span></a
                >
              </div>
              <!-- Modal -->
              <div
                class="modal fade"
                id="videoModal"
                tabindex="-1"
                aria-labelledby="videoModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <div class="modal-body">
                      <div class="_video-wrap">
                        <iframe
                          width="100%"
                          height="300"
                          :src="about.youtube_link"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <!-- <img
                class="_embed-image"
                src="../assets/intro-content.svg"
                alt=""
              /> -->
              <div class="block-divs">
                <div class="grid-item-1">
                  <div class="block-a"></div>
                </div>
                <div class="grid-item-2">
                  <img :src="colorBox1.image" alt="icon" />
                  <h6>{{ colorBox1.title }}</h6>
                  <p>
                    {{ colorBox1.description }}
                  </p>
                </div>
                <div class="grid-item-3">
                  <img :src="colorBox2.image" alt="icon" />
                  <h6>{{ colorBox2.title }}</h6>
                  <p>
                    {{ colorBox2.description }}
                  </p>
                </div>
                <div class="grid-item-4">
                  <img :src="colorBox3.image" alt="icon" />
                  <h6>{{ colorBox3.title }}</h6>
                  <p>
                    {{ colorBox3.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricing-section">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-md-8">
              <div class="_content">
                <h3 v-html="certificate.title"></h3>
                <!-- <p v-html="certificate.content"></p> -->
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Dignissimos explicabo vero reiciendis maiores nisi accusamus
                  qui excepturi consectetur odit voluptatem. Lorem ipsum dolor
                  sit amet consectetur, adipisicing elit. Dignissimos explicabo
                  vero reiciendis maiores nisi accusamus qui excepturi
                  consectetur odit voluptatem.
                </p>
              </div>
              <div class="_certificate-wrap">
                <p>Certificate included</p>
                <img :src="certificate.image" alt="" />
              </div>
            </div>
            <div class="col-md-4" v-if="isMembershipLoading == false">
              <router-link
                :to="{ name: 'nestMembershipCheckout' }"
                class="btn price-cta"
              >
                <div class="price-text">
                  <img class="nest-logo" src="../assets/nest-logo.png" alt="" />
                  <h2><span>₹</span> {{ membership.price }}</h2>
                  <p>Per head</p>
                  <span class="offer" v-if="membership.offer > 0"
                    >{{ membership.offer }}% Off</span
                  >
                </div>
                <img src="../assets/pricecta-image.svg" alt="" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="programs-section">
        <div class="container">
          <div class="program-wrapper">
            <h3>Programs</h3>
            <p>
              {{ programSection.description }}
            </p>
            <div class="row">
              <div
                class="col-md-3"
                v-for="(skill, skillIndex) in lifeSkills"
                :key="skillIndex"
              >
                <div class="_program-card">
                  <img :src="skill.image" alt="" />
                  <h5>{{ skill.title }}</h5>
                  <buttton class="btn">
                    Learn more <i class="fas fa-plus"></i>
                    <div class="hidden-text">
                      <p>
                        {{ skill.description }}
                      </p>
                    </div>
                  </buttton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="testimonial-section">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="img-grids row g-0">
                <div class="col-md-6">
                  <div
                    class="block"
                    v-for="(testimonial,
                    testimonialIndex) in testimonials.slice(0, 2)"
                    :key="testimonialIndex"
                  >
                    <img :src="testimonial.image" alt="" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="block"
                    v-for="(testimonial,
                    testimonialIndex) in testimonials.slice(2, 4)"
                    :key="testimonialIndex"
                  >
                    <img :src="testimonial.image" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <h2>What did our <span>users</span> say about us</h2>
              <div class="_slides">
                <div
                  id="carouselExampleIndicators"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-indicators">
                    <button
                      v-for="(testimonial, testimonialIndex) in testimonials"
                      :key="testimonialIndex"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      :data-bs-slide-to="testimonialIndex"
                      :class="testimonialIndex == 0 ? 'active' : ''"
                      aria-current="true"
                      :aria-label="'Slide ' + testimonialIndex"
                    ></button>
                  </div>
                  <div class="carousel-inner">
                    <div
                      v-for="(testimonial, testimonialIndex) in testimonials"
                      :key="testimonialIndex"
                      class="carousel-item"
                      :class="testimonialIndex == 0 ? 'active' : ''"
                    >
                      <p class="count">
                        {{ testimonialIndex + 1 }}/{{ testimonials.length }}
                      </p>
                      <p class="_comment">
                        {{ testimonial.content }}
                      </p>

                      <h5>
                        {{ testimonial.first_name }} {{ testimonial.last_name }}
                        <br />
                        <span>{{ testimonial.designation }}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="soon-section" id="soon-section">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="img-wrap" v-if="nestEvent != null">
                <img :src="nestEvent.image" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="_head">
                <h4 v-if="nestEvent != null" v-html="nestEvent.title"></h4>
                <p v-if="nestEvent != null">{{ nestEvent.start_time }}</p>
              </div>
              <div class="_body">
                <div
                  v-if="nestEvent != null"
                  v-html="nestEvent.description"
                ></div>
                <p class="name" v-if="nestEvent != null">
                  <span>By</span>
                  {{ nestEvent.first_name }} {{ nestEvent.last_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="faq-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="_content">
                <h3>Frequently asked questions</h3>
                <p>
                  {{ faqDescription.description }}
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div
                  class="accordion-item"
                  v-for="(faq, faqIndex) in faqs"
                  :key="faqIndex"
                >
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#flush-collapseOne' + faqIndex"
                      aria-expanded="false"
                      :aria-controls="'flush-collapseOne' + faqIndex"
                    >
                      {{ faq.question }}
                    </button>
                  </h2>
                  <div
                    :id="'flush-collapseOne' + faqIndex"
                    class="accordion-collapse collapse"
                    :aria-labelledby="'flush-headingOne' + faqIndex"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      {{ faq.answer }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorLog from "@/errorLog";
import NestEventService from "@/services/NestEventService";
import CircularLoader from "@/components/loaders/CircularLoader";
export default {
  name: "Nest",
  components: { CircularLoader },
  created() {
    this.getNextEvent();
    this.aboutNest();
    this.getLifeSkills();
    this.getTestimonials();
    this.getFaq();
    this.getMembership();
    this.getContents();
  },
  data() {
    return {
      i: "1",
      nestEvent: [],
      about: [],
      status: "",
      lifeSkills: [],
      testimonials: [],
      faqs: [],
      isDataLoading: true,
      membership: [],
      certificate: [],
      isMembershipLoading: true,
      banners: "",
      colorBox1: "",
      colorBox2: "",
      colorBox3: "",
      programSection: "",
      faqDescription: ""
    };
  },
  methods: {
    getContents() {
      NestEventService.getContents()
        .then(result => {
          this.banners = result.data.banner;
          this.colorBox1 = result.data.colorBox1;
          this.colorBox2 = result.data.colorBox2;
          this.colorBox3 = result.data.colorBox3;
          this.programSection = result.data.programSection;
          this.faqDescription = result.data.faqDescription;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getNextEvent() {
      NestEventService.getNextEvent()
        .then(result => {
          this.status = result.data.status; //success or  empty
          console.log(this.status);
          this.nestEvent = result.data.event;
          this.isDataLoading = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    aboutNest() {
      NestEventService.getAboutNest()
        .then(result => {
          this.about = result.data.about;
          this.certificate = result.data.certificate;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getLifeSkills() {
      NestEventService.getLifeSkills()
        .then(result => {
          this.lifeSkills = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getTestimonials() {
      NestEventService.getTestimonials()
        .then(result => {
          this.testimonials = result.data.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getFaq() {
      NestEventService.getFaq()
        .then(result => {
          this.faqs = result.data.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getMembership() {
      NestEventService.getMembership()
        .then(result => {
          console.log(result.data);
          this.membership = result.data.nest_membership;
          this.isMembershipLoading = false;
        })
        .catch(error => {
          console.log(error);
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/solo-event.scss";
h3.active {
  color: #f67f29;
}
</style>
